<template lang="html">
<v-model-table model-name="AppointVipList" :model-api="['models','Moph','MophVips']"
  model-title="รายการนัดหมายเพิ่มเติมพิเศษ"
  model-key="cid"
  :headers="headers" importable serverPagination>
  <template v-slot:modelForm="{data,rules,isCreating}">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field :rules="[rules.require(),rules.numeric('เลขบัตรประชาชนต้องเป็นตัวเลขเท่านั้น'),rules.length(13,'เลขบัตรประชาชนต้องมี 13 หลัก')]" v-model="data.cid" label="เลขบัตรประชาชน"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field :rules="[rules.require()]" v-model="data.name" label="ชื่อ-นามสกุล"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-master-field v-model="data.vaccine" groupKey="covid19VaccineSuggestion" label="ระบุชนิดวัคซีน"  clearable></v-master-field>
        </v-col>
      </v-row>
    </v-container>
  </template>
</v-model-table>
</template>

<script>
export default {
  data: ()=>({
    beginDate: undefined,
    endDate: undefined,
    headers: [
      {
        text: 'เลขประจำตัวประชาชน',
        value: 'cid',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name',
        class: 'body-2'
      },
      {
        text: 'ระบุชนิดวัคซีน',
        value: 'vaccine',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ]
  }),
}
</script>

<style lang="css" scoped>
</style>
